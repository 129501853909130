import React from "react"
import { Link, graphql } from "gatsby"
import TopSideMenu from '../components/topSideMenu'
import $ from 'jquery'
import Seo from '../components/seo'
import 'slick-carousel'
import NoticeList from '../components/noticeList.js'
import TopImage from '../components/topImage.js'

import "../common/css/top.css"

// 画像読み込み
import TopMenu01 from "../images/topmenu01@2x.png"
import TopMenu02 from "../images/topmenu02@2x.png"
import TopMenu04 from "../images/topmenu04@2x.png"
import TopMenu05 from "../images/topmenu08@2x.png"
import TopMenu06 from "../images/topmenu06@2x.png"
import TopMenu07 from "../images/topmenu07@2x.png"
import TopMenu08 from "../images/topmenu09@2x.png"

class IndexPage extends React.Component {
  showCat = (n) => {
    var l = ".top_news_cat" + n;
    $(".top_news_list ul li").css("display", "block");
    $(".top_news_list ul li:nth-of-type(n+16)").css("display", "none");
    if (n !== '0') {
      $(".top_news_list ul li").not(l).css("display", "none");
    }
  }

  componentDidMount = () => {
    $('#top_image').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false
    });

    $('.top_imageTxt').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 8000,
      speed: 1500,
      dots: false,
      arrows: false
    });

    $(".top_news_list ul li:nth-of-type(n+16)").css("display", "none");

    $(".top_news_catList li a").on("click", function () {
      $(".top_news_catList li a").removeClass("current");
      $(this).addClass("current");
      $(".top_news_list ul" + $(this).attr("href")).show();
      $(".top_news_list ul li")
    });
  }

  render = () => {
    const publicURL = (this.props.data.allWpTopSetting.edges[0].node.imageSettings.image6 !== null) ?
      this.props.data.allWpTopSetting.edges[0].node.imageSettings.image6.localFile.publicURL : null

    return (
      <>
        <Seo title="home" />
        <div id="top_imageArea" style={publicURL ? { background: `url(${publicURL}) center center no-repeat`, backgroundSize: `cover` } : null} >
          <TopImage></TopImage>
          <div className="top_imageTxt">
            <div className="mc">鉄道施設を未来に繋げます</div>
            <div className="mc">鉄道施設に携わる方々を繋げます</div>
            <div className="mc">鉄道施設を守る人々を支えます</div>
          </div>
          <div className="top_imageBtn">
            <ul>
              <li>
                <Link to="/outline"><span>協会について</span></Link>
              </li>
            </ul>
          </div>
        </div>
        <div id="top_main">
          <div id="top_main_inner1">
            <div id="top_news">
              <h2>NEWS&nbsp;&amp;&nbsp;TOPICS</h2>
              <div className="top_news_inner">
                <div className="top_news_menu">
                  <ul className="top_news_catList">
                    <li>
                      <a href="javascript:void(0)" role="button" tabIndex={0} onClick={() => this.showCat('0')} className="current">すべて</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" role="button" tabIndex={0} onClick={() => this.showCat('1')}>協会からのお知らせ</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" role="button" tabIndex={0} onClick={() => this.showCat('2')}>保安講習会情報</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" role="button" tabIndex={0} onClick={() => this.showCat('3')}>その他</a>
                    </li>
                  </ul>
                </div>
                <div className="top_news_list">
                  <ul>
                    <NoticeList></NoticeList>
                  </ul>
                </div>
                <div className="top_news_allList">
                  <Link to="/news_list">一覧を見る</Link>
                </div>
              </div>
            </div>
            <TopSideMenu />
          </div>
        </div>

        <div id="top_menu">
          <h2>CONTENTS</h2>
          <div className="top_menu_list">
            <ul>
              <li><Link to="/member"><img src={TopMenu01} alt="入会案内" className="imgFit"></img></Link></li>
              <li><Link to="/training"><img src={TopMenu02} alt="保安講習会" className="imgFit"></img></Link></li>
              <li><Link to="/business"><img src={TopMenu07} alt="業務内容" className="imgFit"></img></Link></li>
              <li><Link to="/education"><img src={TopMenu04} alt="検定　教育" className="imgFit"></img></Link></li>
              <li><Link to="/education-training_intern_foriner"><img src={TopMenu05} alt="外国人技能実習" className="imgFit"></img></Link></li>
              <li><Link to="/tokuteiginou"><img src={TopMenu08} alt="特定技能" className="imgFit"></img></Link></li>
              <li><Link to="/ad"><img src={TopMenu06} alt="広告募集" className="imgFit"></img></Link></li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}


export const query = graphql`
  query{
            allWpTopSetting(sort: {fields: date, order: DESC} ,limit: 1) {
                edges {
                  node {
                    date
                    imageSettings {
                        image6 {
                          localFile {
                            publicURL
                          }
                        }
                    }
                  }
                }
              }
            }`;

export default IndexPage;
